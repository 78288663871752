<template>
    <div>
        <!-- Detailed Info Box -->
        <div class="flex flex-wrap w-90 center mt4 mb5">
            <div class="w-30-l w-50 pa3 box-border">
                <div class="flex items-center pb3">
                    <img src="imgs/revenue.png" alt="" />
                    <div class="pl2">Total Qty of Work in Progress</div>
                </div>
                <div class="f3-l f5 font-w2">{{ rawMaterialsSummary?.stockAtHand || 0 }}</div>
            </div>
            <div class="w-30-l w-50 pa3 box-border">
                <div class="flex items-center pb3">
                    <img src="imgs/revenue.png" alt="" />
                    <div class="pl2 ">Total Value of Work in Progress</div>
                </div>
                <div class="f3-l f5 font-w2">
                    {{ formatAmount(rawMaterialsSummary?.stockAtHandPurchaseValue || 0, $store.state.Settings?.currency) }}
                </div>
            </div>
        </div>
        <!-- Detailed Info Box -->

        <!-- filter and search -->
        <div class="w-90 center">
            <div class="pv3 flex justify-between">
                <div></div>
                <div class="flex ">
                    <div class="searchmenu">
                        <img src="imgs/search.svg" class="pa1" alt="" />
                        <input class="searchBox pl2-l" type="text" name="" placeholder="Search..." />
                    </div>
                    <div class="filter tc ml4-l ml2">
                        <div>
                            <div class="pr2">
                                <Span class="pr3 v-mid"><img src="imgs/filter_list.svg" alt=""/></Span> Filter
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- filter and search -->

        <!-- SKU NAME QTY SALES PRICE COST TOTAL -->
        <div class="scrollmenu w-90 center">
            <table class="w-100 tl pt3 bt b--black-20" cellspacing="0">
                <thead>
                    <tr>
                        <th class="pb3 bb b--black-20 pr3  tl">SKU</th>
                        <th class="pb3 bb b--black-30 pr3  tl">NAME</th>
                        <th class="pb3 bb b--black-20  pr3 tl">Average Cost</th>
                        <th class="pb3 bb b--black-20 pr3  tc">Units</th>
                        <th class="pb3 bb b--black-20 pr3  tl">Total Cost</th>
                        <th class="pb3 bb b--black-20 pr3  tl"></th>
                    </tr>
                </thead>
                <tbody v-if="rawMaterials.length">
                    <tr v-for="(data, index) in rawMaterials" class="font-w1" :key="index">
                        <td class="pv3 pr3 bb b--black-20 tl">
                            <router-link :to="{ name: 'Product', params: { id: data._id } }" class="b">{{ data.sku }}</router-link>
                        </td>
                        <td class="pv3 pr3 bb b--black-30 tl">
                            <router-link :to="{ name: 'Product', params: { id: data._id } }" class="b">{{ data.name }}</router-link>
                        </td>
                        <td class="pv3 pr3 bb b--black-20 tl">
                            {{ formatAmount(data?.buyingPrice || 0, $store.state.Settings?.currency) }}
                        </td>
                        <td class="pv3 pr3 bb b--black-20 tc">{{ data?.wip || 0 }}</td>
                        <td class="pv3 pr3 bb b--black-20 tl">
                            {{ formatAmount((data?.buyingPrice || 0) * (data?.wip || 0), $store.state.Settings?.currency) }}
                        </td>
                        <td class="pv3 pr3 bb b--black-20 tc">
                            <a href="" @click.prevent="archive(data)" title="Archive" class="tc">
                                <img :src="require('@/assets/images/delete.svg')" alt="archive" />
                            </a>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="7">
                            <TableEmptyState 
                                :data-array="rawMaterials" 
                                message="No finished product" 
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- pagination -->
        <div v-if="rawMaterials.length" class="w-90 center pb5">
            <div class="flex justify-between items-center pa3 font-w1" style="background: #F6F8FE;">
                <div class="flex items-center ">
                    <div class="pr3-l pr2">
                        Showing
                    </div>
                    <div
                        class="flex items-center pa1 box-border"
                        style="border: 1px solid rgb(151, 145, 145); background: #ffffff;"
                    >
                        25 &nbsp; <img src="imgs/down-o.svg" alt="" />
                    </div>
                    <div class="pl3 db-l dn">items per page</div>
                </div>
                <div class="flex">
                    <div class="active-page-number">1</div>
                    <div class="pl2 pl3-l">2</div>
                    <div class="pl2 pl3-l active-page-number">Next</div>
                    <div class="pl2 pl3-l">End</div>
                </div>
            </div>
        </div>
        <!-- pagination -->
    </div>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import Swal from 'sweetalert2'
import AppWrapper from '@/layout/AppWrapper'
import TableEmptyState from '@/components/TableEmptyState'
import { formatAmount } from '@/utils/lib'

export default {
    name: 'RawMaterial',
    components: { AppWrapper, TableEmptyState },

    setup() {
        const store = useStore()

        const rawMaterialsSummary = computed(() => store.state.Production.productionSummary)
        const rawMaterials = computed(() => store.state.Production.items)

        const archive = product => {
            const quantity = product?.purchase_qty ? parseFloat(product?.purchase_qty) - parseFloat(product?.sales_qty || 0) : 0
            if (quantity != 0) {
                Swal.fire(`Sorry, you can only archive a raw material with 0 quantity in stock`, '', 'info')
                return
            }

            Swal.fire({
                title: `Are you sure you want to archive this raw material - ${product.name}?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Yes, archive it!',
                denyButtonText: `No, cancel!`
            }).then(result => {
                if (result.isConfirmed) {
                    store.dispatch('Production/archiveProduct', {
                        id: product._id,
                        type: 'RAW_MATERIAL'
                    })
                }
            })
        }

        onMounted(() => {
            store.dispatch('Production/getProductionSummary', 'WIP')
            store.dispatch('Production/getProduction', 'WIP')
        })

        return {
            rawMaterialsSummary,
            rawMaterials,
            formatAmount,
            archive
        }
    }
}
</script>

<style scoped></style>
